import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "default" });
        // } else {
        // Continue to the login page
        // next();
        // }
      },
    },
  },
  {
    path: "/profil",
    name: "profil",
    component: () => import("./views/account/profil"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "default" });
        // } else {
        // Continue to the login page
        // next();
        // }
      },
    },
  },
  {
    path: "/lockscreen",
    name: "lockscreen",
    meta: { authRequired: true },
    component: () => import("./views/account/lockscreen"),
  },
  {
    path: "/new-password/:token",
    name: "New password",
    component: () => import("./views/account/new-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(route =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/pages/404",
    name: "Error-404",
    meta: { authRequired: false },
    component: () => import("./views/utility/404"),
  },
  {
    path: "/pages/500",
    name: "Error-500",
    meta: { authRequired: false },
    component: () => import("./views/utility/500"),
  },

  // All Master
  {
    path: "/master/jabatan",
    name: "all-jabatan",
    meta: { authRequired: true },
    component: () => import("./views/master/jabatan/all-jabatan"),
  },
  {
    path: "/master/jabatan/tambah",
    name: "add-jabatan",
    meta: { authRequired: true },
    component: () => import("./views/master/jabatan/add-jabatan"),
  },
  {
    path: "/master/jabatan/edit/:jb_id",
    name: "edit-jabatan",
    meta: { authRequired: true },
    component: () => import("./views/master/jabatan/edit-jabatan"),
  },

  {
    path: "/master/tipe-surat-keluar",
    name: "all-tipe-surat-keluar",
    meta: { authRequired: true },
    component: () =>
      import("./views/master/tipe_surat_keluar/all-tipe_surat_keluar"),
  },
  {
    path: "/master/tipe-surat-keluar/tambah",
    name: "add-tipe-surat-keluar",
    meta: { authRequired: true },
    component: () =>
      import("./views/master/tipe_surat_keluar/add-tipe_surat_keluar"),
  },
  {
    path: "/master/tipe-surat-keluar/edit/:tsk_id",
    name: "edit-tipe-surat-keluar",
    meta: { authRequired: true },
    component: () =>
      import("./views/master/tipe_surat_keluar/edit-tipe_surat_keluar"),
  },

  {
    path: "/master/klasifikasi",
    name: "all-klasifikasi",
    meta: { authRequired: true },
    component: () => import("./views/master/klasifikasi/all-klasifikasi"),
  },
  {
    path: "/master/klasifikasi/tambah",
    name: "add-klasifikasi",
    meta: { authRequired: true },
    component: () => import("./views/master/klasifikasi/add-klasifikasi"),
  },
  {
    path: "/master/klasifikasi/edit/:tsk_id",
    name: "edit-klasifikasi",
    meta: { authRequired: true },
    component: () => import("./views/master/klasifikasi/edit-klasifikasi"),
  },

  {
    path: "/master/konfigurasi_ekstensi",
    name: "all-konfigurasi_ekstensi",
    meta: { authRequired: true },
    component: () => import("./views/master/konfigurasi_ekstensi/all-konfigurasi_ekstensi"),
  },
  {
    path: "/master/konfigurasi_ekstensi/tambah",
    name: "add-konfigurasi_ekstensi",
    meta: { authRequired: true },
    component: () => import("./views/master/konfigurasi_ekstensi/add-konfigurasi_ekstensi"),
  },
  {
    path: "/master/konfigurasi_ekstensi/edit/:id",
    name: "edit-konfigurasi_ekstensi",
    meta: { authRequired: true },
    component: () => import("./views/master/konfigurasi_ekstensi/edit-konfigurasi_ekstensi"),
  },

  {
    path: "/master/lingkup",
    name: "all-lingkup",
    meta: { authRequired: true },
    component: () => import("./views/master/lingkup/all-lingkup"),
  },
  {
    path: "/master/lingkup/tambah",
    name: "add-lingkup",
    meta: { authRequired: true },
    component: () => import("./views/master/lingkup/add-lingkup"),
  },
  {
    path: "/master/lingkup/edit/:tsk_id",
    name: "edit-lingkup",
    meta: { authRequired: true },
    component: () => import("./views/master/lingkup/edit-lingkup"),
  },

  {
    path: "/master/kategori-surat",
    name: "all-kategori_surat",
    meta: { authRequired: true },
    component: () => import("./views/master/kategori_surat/all-kategori_surat"),
  },
  {
    path: "/master/kategori-surat/tambah",
    name: "add-kategori_surat",
    meta: { authRequired: true },
    component: () => import("./views/master/kategori_surat/add-kategori_surat"),
  },
  {
    path: "/master/kategori-surat/edit/:tsk_id",
    name: "edit-kategori_surat",
    meta: { authRequired: true },
    component: () =>
      import("./views/master/kategori_surat/edit-kategori_surat"),
  },

  {
    path: "/master/user",
    name: "all-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/all-user"),
  },
  {
    path: "/master/user/tambah",
    name: "add-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/add-user"),
  },
  {
    path: "/master/user/edit/:tsk_id",
    name: "edit-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/edit-user"),
  },

  {
    path: "/master/kantor",
    name: "all-kantor",
    meta: { authRequired: true },
    component: () => import("./views/master/kantor/all-kantor"),
  },
  {
    path: "/master/kantor/tambah",
    name: "add-kantor",
    meta: { authRequired: true },
    component: () => import("./views/master/kantor/add-kantor"),
  },
  {
    path: "/master/kantor/edit/:tsk_id",
    name: "edit-kantor",
    meta: { authRequired: true },
    component: () => import("./views/master/kantor/edit-kantor"),
  },

  {
    path: "/master/keuntungan",
    name: "all-keuntungan",
    meta: { authRequired: true },
    component: () => import("./views/master/keuntungan/all-keuntungan"),
  },
  {
    path: "/master/keuntungan/tambah",
    name: "add-keuntungan",
    meta: { authRequired: true },
    component: () => import("./views/master/keuntungan/add-keuntungan"),
  },
  {
    path: "/master/keuntungan/edit/:tsk_id",
    name: "edit-keuntungan",
    meta: { authRequired: true },
    component: () => import("./views/master/keuntungan/edit-keuntungan"),
  },

  {
    path: "/master/tipe_vendor",
    name: "all-tipe_vendor",
    meta: { authRequired: true },
    component: () => import("./views/master/tipe_vendor/all-tipe_vendor"),
  },
  {
    path: "/master/tipe_vendor/tambah",
    name: "add-tipe_vendor",
    meta: { authRequired: true },
    component: () => import("./views/master/tipe_vendor/add-tipe_vendor"),
  },
  {
    path: "/master/tipe_vendor/edit/:id",
    name: "edit-tipe_vendor",
    meta: { authRequired: true },
    component: () => import("./views/master/tipe_vendor/edit-tipe_vendor"),
  },

  {
    path: "/master/departemen",
    name: "all-departemen",
    meta: { authRequired: true },
    component: () => import("./views/master/departemen/all-departemen"),
  },
  {
    path: "/master/departemen/edit/:id",
    name: "edit-departemen",
    meta: { authRequired: true },
    component: () => import("./views/master/departemen/edit-departemen"),
  },

  {
    path: "/master/breakdown",
    name: "all-breakdown",
    meta: { authRequired: true },
    component: () => import("./views/master/breakdown/all-breakdown"),
  },
  {
    path: "/master/breakdown/tambah",
    name: "add-breakdown",
    meta: { authRequired: true },
    component: () => import("./views/master/breakdown/add-breakdown"),
  },
  {
    path: "/master/breakdown/edit/:id",
    name: "edit-breakdown",
    meta: { authRequired: true },
    component: () => import("./views/master/breakdown/edit-breakdown"),
  },

  {
    path: "/master/posisi",
    name: "all-posisi",
    meta: { authRequired: true },
    component: () => import("./views/master/posisi/all-posisi"),
  },
  {
    path: "/master/posisi/tambah",
    name: "add-posisi",
    meta: { authRequired: true },
    component: () => import("./views/master/posisi/add-posisi"),
  },
  {
    path: "/master/posisi/edit/:id",
    name: "edit-posisi",
    meta: { authRequired: true },
    component: () => import("./views/master/posisi/edit-posisi"),
  },

  {
    path: "/master/posisi_jabatan",
    name: "all-posisi_jabatan",
    meta: { authRequired: true },
    component: () => import("./views/master/posisi_jabatan/all-posisi_jabatan"),
  },
  {
    path: "/master/posisi_jabatan/tambah",
    name: "add-posisi_jabatan",
    meta: { authRequired: true },
    component: () => import("./views/master/posisi_jabatan/add-posisi_jabatan"),
  },
  {
    path: "/master/posisi_jabatan/edit/:id",
    name: "edit-posisi_jabatan",
    meta: { authRequired: true },
    component: () =>
      import("./views/master/posisi_jabatan/edit-posisi_jabatan"),
  },

  {
    path: "/master/divisi",
    name: "all-divisi",
    meta: { authRequired: true },
    component: () => import("./views/master/divisi/all-divisi"),
  },
  {
    path: "/master/divisi/tambah",
    name: "add-divisi",
    meta: { authRequired: true },
    component: () => import("./views/master/divisi/add-divisi"),
  },
  {
    path: "/master/divisi/edit/:id",
    name: "edit-divisi",
    meta: { authRequired: true },
    component: () => import("./views/master/divisi/edit-divisi"),
  },

  {
    path: "/master/komite",
    name: "all-komite",
    meta: { authRequired: true },
    component: () => import("./views/master/komite/all-komite"),
  },
  {
    path: "/master/komite/tambah",
    name: "add-komite",
    meta: { authRequired: true },
    component: () => import("./views/master/komite/add-komite"),
  },
  {
    path: "/master/komite/edit/:id",
    name: "edit-komite",
    meta: { authRequired: true },
    component: () => import("./views/master/komite/edit-komite"),
  },
  {
    path: "/master/konfigurasi/edit",
    name: "edit-konfigurasi",
    meta: { authRequired: true },
    component: () => import("./views/master/konfigurasi/edit-konfigurasi"),
  },

  // Surat Masuk
  {
    path: "/surat-masuk",
    name: "all-surat_masuk",
    meta: { authRequired: true },
    component: () => import("./views/surat_masuk/all-surat_masuk"),
  },
  {
    path: "/surat-masuk/diajukan",
    name: "surat-masuk-diajukan",
    meta: { authRequired: true },
    component: () => import("./views/surat_masuk/all-surat_masuk_diajukan"),
  },

  {
    path: "/surat-masuk/didisposisikan",
    name: "surat-masuk-didisposisikan",
    meta: { authRequired: true },
    component: () =>
      import("./views/surat_masuk/all-surat_masuk_didisposisikan"),
  },

  {
    path: "/surat-masuk/ditindaklanjuti",
    name: "surat-masuk-ditindaklanjuti",
    meta: { authRequired: true },
    component: () =>
      import("./views/surat_masuk/all-surat_masuk_ditindaklanjuti"),
  },

  {
    path: "/surat-masuk/draft",
    name: "surat-masuk-draft",
    meta: { authRequired: true },
    component: () => import("./views/surat_masuk/all-surat_masuk_draft"),
  },
  {
    path: "/surat-masuk/inbox",
    name: "surat-masuk-inbox",
    meta: { authRequired: true },
    component: () => import("./views/surat_masuk/all-surat_masuk_inbox"),
  },
  {
    path: "/surat-masuk/detail/:id",
    name: "detail-surat_masuk",
    meta: { authRequired: true },
    component: () => import("./views/surat_masuk/detail-surat_masuk"),
  },
  {
    path: "/surat-masuk/edit/:id",
    name: "edit-surat_masuk",
    meta: { authRequired: true },
    component: () => import("./views/surat_masuk/edit-surat_masuk"),
  },
  {
    path: "/surat-masuk/edit-ajukan/:id",
    name: "edit-surat_masuk_ajukan",
    meta: { authRequired: true },
    component: () => import("./views/surat_masuk/edit-surat_masuk_ajukan"),
  },
  {
    path: "/surat-masuk/tambah",
    name: "add-surat_masuk",
    meta: { authRequired: true },
    component: () => import("./views/surat_masuk/add-surat_masuk"),
  },

  // Surat Keluar
  {
    path: "/surat-keluar",
    name: "all-surat_keluar",
    meta: { authRequired: true },
    component: () => import("./views/surat_keluar/all-surat_keluar"),
  },

  {
    path: "/surat-keluar/booking",
    name: "surat_keluar_booking",
    meta: { authRequired: true },
    component: () => import("./views/surat_keluar/all-surat_keluar_booking"),
  },

  {
    path: "/surat-keluar/detail/:id",
    name: "detail-surat_keluar",
    meta: { authRequired: true },
    component: () => import("./views/surat_keluar/detail-surat_keluar"),
  },
  {
    path: "/surat-keluar/edit/:tipe/:id",
    name: "edit-surat_keluar",
    meta: { authRequired: true },
    component: () => import("./views/surat_keluar/edit-surat_keluar"),
  },
  {
    path: "/surat-keluar/tambah/:tipe",
    name: "add-surat_keluar",
    meta: { authRequired: true },
    component: () => import("./views/surat_keluar/add-surat_keluar"),
  },

  // Administrasi Absensi
  // Rapat Direksi
  {
    path: "/administrasi/rapat-direksi",
    name: "rapat-direksi",
    meta: { authRequired: true },
    component: () => import("./views/absensi/rapat_direksi/rapat-direksi"),
  },
  // tambah data Rapat Direksi
  {
    path: "/administrasi/rapat-direksi/tambah",
    name: "add-rapat-direksi",
    meta: { authRequired: true },
    component: () => import("./views/absensi/rapat_direksi/add-rapat-direksi"),
  },
  // edit data Rapat Direksi
  {
    path: "/administrasi/rapat-direksi/edit/:rd_id",
    name: "edit-rapat-direksi",
    meta: { authRequired: true },
    component: () => import("./views/absensi/rapat_direksi/edit-rapat-direksi"),
  },
  {
    path: "/administrasi/rapat-direksi/detail/:rd_id",
    name: "detail-rapat-direksi",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat_direksi/detail-rapat-direksi"),
  },
  {
    path: "/administrasi/rapat-direksi/ttd-barcode/:id",
    name: "absen-rapat-direksi",
    component: () =>
      import("./views/absensi/rapat_direksi/absen-rapat-direksi"),
  },

  // Rapat Komite Direksi

  {
    path: "/administrasi/rapat-komite-direksi",
    name: "rapat-komite-direksi",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat_komite_direksi/rapat-komite-direksi"),
  },
  //tambah data Rapat Komite Direksi

  {
    path: "/administrasi/rapat-komite-direksi/tambah",
    name: "add-rapat-komite-direksi",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat_komite_direksi/add-rapat-komite-direksi"),
  },
  //edit data Rapat Komite Direksi
  {
    path: "/administrasi/rapat-komite-direksi/edit/:id",
    name: "edit-rapat-komite-direksi",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat_komite_direksi/edit-rapat-komite-direksi"),
  },
  // detail rapat komit direksi
  {
    path: "/administrasi/rapat-komite-direksi/detail/:id",
    name: "detail-rapat-komite-direksi",
    meta: { authRequired: true },
    component: () =>
      import(
        "./views/absensi/rapat_komite_direksi/detail-rapat-komite-direksi"
      ),
  },
  {
    path: "/administrasi/rapat-komite-direksi/ttd-barcode/:id",
    name: "absen-rapat-komite-direksi",
    component: () =>
      import("./views/absensi/rapat_komite_direksi/absen-rapat-komite-direksi"),
  },
  //rapat komisaris
  {
    path: "/administrasi/rapat-komisaris",
    name: "rapat-komisaris",
    meta: { authRequired: true },
    component: () => import("./views/absensi/rapat_komisaris/rapat-komisaris"),
  },
  //tambah data Rapat Komisaris
  {
    path: "/administrasi/rapat-komisaris/tambah",
    name: "add-rapat-komisaris",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat_komisaris/add-rapat-komisaris"),
  },
  //edit data Rapat Komite Direksi
  {
    path: "/administrasi/rapat-komisaris/edit/:id",
    name: "edit-rapat-komisaris",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat_komisaris/edit-rapat-komisaris"),
  },
  // detail rapat komit direksi
  {
    path: "/administrasi/rapat-komisaris/detail/:id",
    name: "detail-rapat-komisaris",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat_komisaris/detail-rapat-komisaris"),
  },

  {
    path: "/administrasi/rapat-komisaris/ttd-barcode/:id",
    name: "absen-rapat-komisaris",
    component: () =>
      import("./views/absensi/rapat_komisaris/absen-rapat-komisaris"),
  },

  // Rapat gabungan

  {
    path: "/administrasi/rapat-gabungan",
    name: "rapat-gabungan",
    meta: { authRequired: true },
    component: () => import("./views/absensi/rapat-gabungan/rapat-gabungan"),
  },
  // tambah data Rapat gabungan
  {
    path: "/administrasi/rapat-gabungan/tambah",
    name: "add-rapat-gabungan",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat-gabungan/add-rapat-gabungan"),
  },
  {
    path: "/administrasi/rapat-gabungan/edit/:id",
    name: "edit-rapat-gabungan",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat-gabungan/edit-rapat-gabungan"),
  },
  {
    path: "/administrasi/rapat-gabungan/detail/:id",
    name: "detail-rapat-gabungan",
    meta: { authRequired: true },
    component: () =>
      import("./views/absensi/rapat-gabungan/detail-rapat-gabungan"),
  },
  {
    path: "/administrasi/rapat-gabungan/ttd-barcode/:id",
    name: "absen-rapat-gabungan",
    component: () =>
      import("./views/absensi/rapat-gabungan/absen-rapat-gabungan"),
  },

  {
    path: "/digitaliasi/cv",
    name: "cv",
    meta: { authRequired: true },
    component: () => import("./views/digitalisasi/cv/cv"),
  },
  {
    path: "/digitaliasi/cv/edit/:token",
    name: "edit-cv",
    meta: { authRequired: true },
    component: () => import("./views/digitalisasi/cv/edit-cv"),
  },
  {
    path: "/digitaliasi/cv/detail/:token",
    name: "detail-cv",
    meta: { authRequired: true },
    component: () => import("./views/digitalisasi/cv/detail-cv"),
  },

  // Digitalisasi SPK
  {
    path: "/digitaliasi/spk",
    name: "spk",
    meta: { authRequired: true },
    component: () => import("./views/digitalisasi/spk/spk"),
  },
  {
    path: "/digitaliasi/spk/tambah/",
    name: "add-spk",
    meta: { authRequired: true },
    component: () => import("./views/digitalisasi/spk/add-spk"),
  },
  {
    path: "/digitaliasi/spk/edit/:id",
    name: "edit-spk",
    meta: { authRequired: true },
    component: () => import("./views/digitalisasi/spk/edit-spk"),
  },
  {
    path: "/digitaliasi/spk/detail/:id",
    name: "detail-spk",
    meta: { authRequired: true },
    component: () => import("./views/digitalisasi/spk/detail-spk"),
  },

  // Monitoring Sponsorship
  {
    path: "/monitoring/sponsorship",
    name: "sponsorship",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/sponsorship/all-sponsorship"),
  },
  {
    path: "/monitoring/sponsorship/tambah",
    name: "add-sponsorship",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/sponsorship/add-sponsorship"),
  },
  {
    path: "/monitoring/sponsorship/edit/:id",
    name: "edit-sponsorship",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/sponsorship/edit-sponsorship"),
  },
  {
    path: "/monitoring/sponsorship/detail/:id",
    name: "detail-sponsorship",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/sponsorship/detail-sponsorship"),
  },

  // Monitoring Notulen
  {
    path: "/monitoring/notulen",
    name: "notulen",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/notulen/all-notulen"),
  },
  {
    path: "/monitoring/notulen/tambah",
    name: "add-notulen",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/notulen/add-notulen"),
  },
  {
    path: "/monitoring/notulen/detail/:id",
    name: "detail-notulen",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/notulen/detail-notulen"),
  },
  {
    path: "/monitoring/notulen/edit/:id",
    name: "edit-notulen",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/notulen/edit-notulen"),
  },
  {
    path: "/monitoring/notulen/ttd-notulen/:id",
    name: "absen-notulen",
    component: () =>
      import("./views/monitoring/notulen/tanda-tangan-notulen"),
  },

  // Monitoring Vendor
  {
    path: "/monitoring/vendor",
    name: "vendor",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/vendor/all-vendor"),
  },
  {
    path: "/monitoring/vendor/tambah",
    name: "add-vendor",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/vendor/add-vendor"),
  },
  {
    path: "/monitoring/vendor/edit/:id",
    name: "edit-vendor",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/vendor/edit-vendor"),
  },
  {
    path: "/monitoring/vendor/detail/:id",
    name: "detail-vendor",
    meta: { authRequired: true },
    component: () => import("./views/monitoring/vendor/detail-vendor"),
  },
];
